$primary-red: #d84d48;
$primary-blue: #2a70ff;
$primary-green: #63cc6d;
$primary-orange: #ffa800;

$grey-1: #0c111b;
$grey-2: #171e2d;
$grey-3: #434a58;
$grey-4: #7e8490;
$grey-5: #c4c6ca;
$grey-6: #e4e5e7;
$grey-7: #f3f3f3;
$grey-8: #fafafa;
$grey-9: #ffffff;

$light-blue: #dfeaff;
$dark-blue: darken($primary-blue, 10%);

$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
