@import "variables";

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2px;
  display: block;
  color: $grey-1;
}

.date-range {
  padding: 0 0 0 22px;
  margin: 0 0 16px 0;
  .field {
    margin: 8px 0;
    width: 180px;
  }

  .label {
    color: $grey-4;
    margin-bottom: 0;
  }
}
